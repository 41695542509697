export const newsData = {
    "tb_09_2023": {
        id: "tb_09_2023",
        title: "Thông báo đấu giá số 09/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_09_2023.pdf",
        image: "/assets/images/TBDG/tb_09_2023_1.png",
        date: "14-04-2023",

    },
    "tb_08_2023": {
        id: "tb_08_2023",
        title: "Thông báo đấu giá số 08/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_08_2023_1.pdf",
        image: "/assets/images/TBDG/tb_08_2023_1.png",
        date: "17-04-2023",
    },
    "TB_15_2023_0004": {
        id: "TB_15_2023_0004",
        title: "Thông báo đấu giá số 15/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_15_2023_0004.pdf",
        image: "/assets/images/TBDG/TB_15_2023_0004.png",
        date: "27-04-2023",
    },
    "TB_23_2023_0001": {
        id: "TB_23_2023_0001",
        title: "Thông báo đấu giá số 23/TBĐG-CTMT",
        pdfFile: "/assets/TBDG/DAU_GIA_TINH_05_2023/TB_23_2023_0001.pdf",
        image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
        date: "13-05-2023",
        images: [
            {
                id: "SODO_DAU_GIA",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
            },
            {
                id: "KV_K20.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_K20.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_1.jpg",
            },
            //
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/13_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2_LO.png",
            },
            {
                id: "KV_ODT_16_17_1_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/13_05_2023/KV_ODT_16_17_1_LO.png",
            },
        ]
    },
    "TB_26_2023": {
        id: "TB_26_2023",
        title: "Thông báo đấu giá số 26/TBĐG-CTMT",
        pdfFile: "/assets/TBDG/DAU_GIA_TINH_05_2023/TB_26_2023.pdf",
        image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
        date: "20-05-2023",
        images: [
            {
                id: "SODO_DAU_GIA",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/SODO_DAU_GIA.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_2.jpg",
            },
            {
                id: "KDT_NAM_DONG_HA_GIAI_DOAN_3.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KDT_NAM_DONG_HA_GIAI_DOAN_3.jpg",
            },
            {
                id: "KV_K20.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_K20.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_1.jpg",
            },
            {
                id: "KV_ODT_16_17_1.jpg",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/KV_ODT_16_17_2.jpg",
            },
            //
            {
                id: "KV_K20_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_K20_LO.png",
            },
            {
                id: "KV_ODT_16_17_1_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_ODT_16_17_1_LO.png",
            },
            {
                id: "KV_ODT_16_17_2_LO.png",
                image: "/assets/TBDG/DAU_GIA_TINH_05_2023/20_05_2023/KV_ODT_16_17_2_LO.png",
            }
        ]
    },
    "TB_37_PL_0001": {
        id: "TB_37_PL_0001",
        title: "Thông báo đấu giá số 37/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_37_PL_0001.pdf",
        image: "/assets/images/TBDG/tb_37_pl_0001.png",
        date: "10-06-2023",
    },
    "TB_59_2023": {
        id: "TB_59_2023",
        title: "Thông báo đấu giá số 59/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_59.2023_VA_PL_GIA.pdf",
        image: "/assets/images/TBDG/TB_59.jpg",
        date: "24-06-2023",
    },
    "TB_64_2023": {
        id: "TB_64_2023",
        title: "Thông báo đấu giá số 64/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_64.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_64_2023.jpg",
        date: "23-06-2023",
    },
    "TB_66_2023": {
        id: "TB_66_2023",
        title: "Thông báo đấu giá số 66/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_66.20236_0001.pdf",
        image: "/assets/images/TBDG/TB_66_2023.jpg",
        date: "23-06-2023",
    },
    "TB_88_2023": {
        id: "TB_88_2023",
        title: "Thông báo đấu giá số 88/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_88.2023.pdf",
        image: "/assets/images/TBDG/TB_88.2023.jpg",
        date: "09-07-2023",
    },
    "TB_91_2023": {
        id: "TB_91_2023",
        title: "Thông báo đấu giá số 91/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_91.2023.pdf",
        image: "/assets/images/TBDG/TB_91.2023.jpg",
        date: "09-07-2023",
    },
    "TB_94_2023": {
        id: "TB_94_2023",
        title: "Thông báo đấu giá số 94/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_94.2023.pdf",
        image: "/assets/images/TBDG/TB_94.2023.jpg",
        date: "10-07-2023",
    },
    "TB_125_2023": {
        id: "TB_125_2023",
        title: "Thông báo đấu giá số 125/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_125_2023.pdf",
        image: "/assets/images/TBDG/TB_125_2023.png",
        date: "11-08-2023",
    },
    "TB_QC_TAU_THADS_TP_0001": {
        id: "TB_QC_TAU_THADS_TP_0001",
        title: "Thông báo đấu giá số 130/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_TAU_THADS_TP_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_TAU_THADS_TP_0001.jpg",
        date: "14-08-2023",
    },
    "TB_QC_THA_DONG_HA_CHI_LY_0001": {
        id: "TB_QC_THA_DONG_HA_CHI_LY_0001",
        title: "Thông báo đấu giá số 125/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_THA_DONG_HA_CHI_LY_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_THA_DONG_HA_CHI_LY_0001.jpg",
        date: "11-08-2023",
    },
    "TB_QC_THA_THI_XA_0001": {
        id: "TB_QC_THA_THI_XA_0001",
        title: "Thông báo đấu giá số 127/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_THA_THI_XA_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_THA_THI_XA_0001.jpg",
        date: "11-08-2023",
    },
    "TB_QC_VIETINBNAK_0001": {
        id: "TB_QC_VIETINBNAK_0001",
        title: "Thông báo đấu giá số 116/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_VIETINBNAK_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_VIETINBNAK_0001.jpg",
        date: "03-08-2023",
    },
    "TB_QC_XE_THA_TP_DH_0001": {
        id: "TB_QC_XE_THA_TP_DH_0001",
        title: "Thông báo đấu giá số 114/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_QC_XE_THA_TP_DH_0001.pdf",
        image: "/assets/images/TBDG/TB_QC_XE_THA_TP_DH_0001.jpg",
        date: "28-07-2023",
    },
    "TB_145_2023": {
        id: "TB_145_2023",
        title: "Thông báo đấu giá số 145/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_145.2023_146.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_145_2023.jpg",
        date: "26-08-2023",
    },

    "TB_157_2023": {
        id: "TB_157_2023",
        title: "Thông báo đấu giá số 157/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TB_157.2023_158.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_157_2023.jpg",
        date: "28-08-2023",
    },
    "TB_148_2023": {
        id: "TB_148_2023",
        title: "Thông báo đấu giá số 148/TBĐG-CTMT",
        pdfFile: "/assets/pdf/XE_TB_148.2023_149.2023_0001.pdf",
        image: "/assets/images/TBDG/TB_148_2023.jpg",
        date: "18-08-2023",
    },
    "TBDG_53_22022024": {
        id: "TBDG_53_22022024",
        title: "Thông báo đấu giá số 53/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TBDG_53_2024.pdf",
        image: "/assets/images/TBDG/TBDG_53_22022024.jpg",
        date: "13-03-2024",
    },
    "TBDB_52_22022024": {
        id: "TBDB_52_22022024",
        title: "Thông báo đấu giá số 52/TBĐG-CTMT",
        pdfFile: "/assets/pdf/TBDB_52_2024_36.pdf",
        image: "/assets/images/TBDG/TBDB_52_22022024.jpg",
        date: "16-03-2024",
    },
    "TBDG_31_STNMT_0001": {
        id:"TBDG_31_STNMT_0001",
        title:"Thông báo đấu giá số 31/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_31_STNMT_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_31_STNMT_0001.pdf",
        date: "30-01-2024"
    },
    "TBDG_82_0001": {
        id:"TBDG_82_0001",
        title:"Thông báo đấu giá số 82/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_82_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_82_0001.pdf",
        date: "08-04-2024"
    },
    "TBDG_84_0001": {
        id:"TBDG_84_0001",
        title:"Thông báo đấu giá số 84/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_84_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_84_0001.pdf",
        date: "08-04-2024"
    },
    "TBDG_95_TAN_LONG_0001": {
        id:"TBDG_95_TAN_LONG_0001",
        title:"Thông báo đấu giá số 95/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_95_TAN_LONG_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_95_TAN_LONG_0001.pdf",
        date: "15-04-2024"
    },
    "TBDG_93_0001": {
        id:"TBDG_93_0001",
        title:"Thông báo đấu giá số 93/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_93_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_93_0001.pdf",
        date: "15-04-2024"
    },
    "TBDG_89_2024": {
        id:"TBDG_89_2024",
        title:"Thông báo đấu giá số 89/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_89_2024.jpg",
        pdfFile:"/assets/pdf/TBDG_89_2024.pdf",
        date: "16-04-2024"
    },
    "TBDG_97_0001": {
        id:"TBDG_97_0001",
        title:"Thông báo đấu giá số 97/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_97_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_97_0001.pdf",
        date: "16-04-2024"
    },
    "TBDG_115_0001": {
        id:"TBDG_115_0001",
        title:"Thông báo đấu giá số 115/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_115_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_115_0001.pdf",
        date: "16-04-2024"
    },
    "TBDG_101_0001": {
        id:"TBDG_101_0001",
        title:"Thông báo đấu giá số 101/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_101_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_101_0001.pdf",
        date: "17-04-2024"
    },
    "TBDG_10808_0001": {
        id:"TBDG_10808_0001",
        title:"Thông báo đấu giá số 108/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_10808_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_10808_0001.pdf",
        date: "21-04-2024"
    },
    "TBDG_117": {
        id:"TBDG_117",
        title:"Thông báo đấu giá số 117/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_117.jpg",
        pdfFile:"/assets/pdf/TBDG_117.pdf",
        date: "22-04-2024"
    },
    "TBDG_119_0001": {
        id:"TBDG_119_0001",
        title:"Thông báo đấu giá số 119/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_119_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_119_0001.pdf",
        date: "22-04-2024"
    },
    "TBDG_122_0001": {
        id:"TBDG_122_0001",
        title:"Thông báo đấu giá số 122/TBĐG-CTMT",
        image: "/assets/images/TBDG/TBDG_122_0001.jpg",
        pdfFile:"/assets/pdf/TBDG_122_0001.pdf",
        date: "24-04-2024"
    },
    "TB_20240923_549": {
            id:"TB_20240923_549",
            title:"Thông báo đấu giá số 549/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20240923_549_img.png",
            pdfFile:"/assets/pdf/TB_20240923_549.pdf",
            date: "23-09-2024"
        },
    "TB_20240925_541": {
            id:"TB_20240925_541",
            title:"Thông báo đấu giá số 541/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20240925_541_img.png",
            pdfFile:"/assets/pdf/TB_20240925_541.pdf",
            date: "25-09-2024"
        },
    "TB_20240927_509_0001": {
            id:"TB_20240927_509_0001",
            title:"Thông báo đấu giá số 509/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20240927_509_0001_img.png",
            pdfFile:"/assets/pdf/TB_20240927_509_0001.pdf",
            date: "27-09-2024"
        },
    "TB_20240927_513": {
            id:"TB_20240927_513",
            title:"Thông báo đấu giá số 513/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20240927_513_img.png",
            pdfFile:"/assets/pdf/TB_20240927_513.pdf",
            date: "27-09-2024"
        },
    "TB_20241001_539": {
            id:"TB_20241001_539",
            title:"Thông báo đấu giá số 539/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241001_539_img.png",
            pdfFile:"/assets/pdf/TB_20241001_539.pdf",
            date: "01-10-2024"
        },
    "TB_20241001_543": {
            id:"TB_20241001_543",
            title:"Thông báo đấu giá số 543/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241001_543_img.png",
            pdfFile:"/assets/pdf/TB_20241001_543.pdf",
            date: "01-10-2024"
        },
    "TB_20241002_555": {
            id:"TB_20241002_555",
            title:"Thông báo đấu giá số 555/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241002_555_img.png",
            pdfFile:"/assets/pdf/TB_20241002_555.pdf",
            date: "02-10-2024"
        },
    "TB_20241002_557": {
            id:"TB_20241002_557",
            title:"Thông báo đấu giá số 557/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241002_557_img.png",
            pdfFile:"/assets/pdf/TB_20241002_557.pdf",
            date: "02-10-2024"
        },
    "TB_20241002_563": {
            id:"TB_20241002_563",
            title:"Thông báo đấu giá số 563/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241002_563_img.png",
            pdfFile:"/assets/pdf/TB_20241002_563.pdf",
            date: "02-10-2024"
        },
    "TB_20241003_533": {
            id:"TB_20241003_533",
            title:"Thông báo đấu giá số 533/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241003_533_img.png",
            pdfFile:"/assets/pdf/TB_20241003_533.pdf",
            date: "03-10-2024"
        },
    "TB_20241003_567": {
            id:"TB_20241003_567",
            title:"Thông báo đấu giá số 567/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241003_567_img.png",
            pdfFile:"/assets/pdf/TB_20241003_567.pdf",
            date: "03-10-2024"
        },
    "TB_20241003_571": {
            id:"TB_20241003_571",
            title:"Thông báo đấu giá số 571/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241003_571_img.png",
            pdfFile:"/assets/pdf/TB_20241003_571.pdf",
            date: "03-10-2024"
        },
    "TB_20241004_559": {
            id:"TB_20241004_559",
            title:"Thông báo đấu giá số 559/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241004_559_img.png",
            pdfFile:"/assets/pdf/TB_20241004_559.pdf",
            date: "04-10-2024"
        },
    "TB_20241004_573": {
            id:"TB_20241004_573",
            title:"Thông báo đấu giá số 573/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241004_573_img.png",
            pdfFile:"/assets/pdf/TB_20241004_573.pdf",
            date: "04-10-2024"
        },
    "TB_20241005_536": {
            id:"TB_20241005_536",
            title:"Thông báo đấu giá số 536/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241005_536_img.png",
            pdfFile:"/assets/pdf/TB_20241005_536.pdf",
            date: "05-10-2024"
        },
    "TB_20241007_561": {
            id:"TB_20241007_561",
            title:"Thông báo đấu giá số 561/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241007_561_img.png",
            pdfFile:"/assets/pdf/TB_20241007_561.pdf",
            date: "07-10-2024"
        },
    "TB_20241007_592": {
            id:"TB_20241007_592",
            title:"Thông báo đấu giá số 592/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241007_592_img.png",
            pdfFile:"/assets/pdf/TB_20241007_592.pdf",
            date: "07-10-2024"
        },
    "TB_20241008_575": {
            id:"TB_20241008_575",
            title:"Thông báo đấu giá số 575/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241008_575_img.png",
            pdfFile:"/assets/pdf/TB_20241008_575.pdf",
            date: "08-10-2024"
        },
    "TB_20241008_577": {
            id:"TB_20241008_577",
            title:"Thông báo đấu giá số 577/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241008_577_img.png",
            pdfFile:"/assets/pdf/TB_20241008_577.pdf",
            date: "08-10-2024"
        },
    "TB_20241009_588": {
            id:"TB_20241009_588",
            title:"Thông báo đấu giá số 588/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241009_588_img.png",
            pdfFile:"/assets/pdf/TB_20241009_588.pdf",
            date: "09-10-2024"
        },
    "TB_20241009_591": {
            id:"TB_20241009_591",
            title:"Thông báo đấu giá số 591/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241009_591_img.png",
            pdfFile:"/assets/pdf/TB_20241009_591.pdf",
            date: "09-10-2024"
        },
    "TB_20241009_621": {
            id:"TB_20241009_621",
            title:"Thông báo đấu giá số 621/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241009_621_img.png",
            pdfFile:"/assets/pdf/TB_20241009_621.pdf",
            date: "09-10-2024"
        },
    "TB_20241009_623": {
            id:"TB_20241009_623",
            title:"Thông báo đấu giá số 623/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241009_623_img.png",
            pdfFile:"/assets/pdf/TB_20241009_623.pdf",
            date: "09-10-2024"
        },
    "TB_20241010_584": {
            id:"TB_20241010_584",
            title:"Thông báo đấu giá số 584/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241010_584_img.png",
            pdfFile:"/assets/pdf/TB_20241010_584.pdf",
            date: "10-10-2024"
        },
    "TB_20241011_579": {
            id:"TB_20241011_579",
            title:"Thông báo đấu giá số 579/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241011_579_img.png",
            pdfFile:"/assets/pdf/TB_20241011_579.pdf",
            date: "11-10-2024"
        },
    "TB_20241014_595": {
            id:"TB_20241014_595",
            title:"Thông báo đấu giá số 595/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241014_595_img.png",
            pdfFile:"/assets/pdf/TB_20241014_595.pdf",
            date: "14-10-2024"
        },
    "TB_20241014_599": {
            id:"TB_20241014_599",
            title:"Thông báo đấu giá số 599/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241014_599_img.png",
            pdfFile:"/assets/pdf/TB_20241014_599.pdf",
            date: "14-10-2024"
        },
    "TB_20241015_605": {
            id:"TB_20241015_605",
            title:"Thông báo đấu giá số 605/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241015_605_img.png",
            pdfFile:"/assets/pdf/TB_20241015_605.pdf",
            date: "15-10-2024"
        },
    "TB_20241015_609": {
            id:"TB_20241015_609",
            title:"Thông báo đấu giá số 609_0001/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241015_609_img.png",
            pdfFile:"/assets/pdf/TB_20241015_609.pdf",
            date: "15-10-2024"
        },
    "TB_20241016_607": {
            id:"TB_20241016_607",
            title:"Thông báo đấu giá số 607/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241016_607_img.png",
            pdfFile:"/assets/pdf/TB_20241016_607.pdf",
            date: "16-10-2024"
        },
    "TB_20241022_611": {
            id:"TB_20241022_611",
            title:"Thông báo đấu giá số 611/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241022_611_img.png",
            pdfFile:"/assets/pdf/TB_20241022_611.pdf",
            date: "22-10-2024"
        },
    "TB_20241022_613": {
            id:"TB_20241022_613",
            title:"Thông báo đấu giá số 613/TBĐG-CTMT",
            image: "/assets/images/TBDG/TB_20241022_613_img.png",
            pdfFile:"/assets/pdf/TB_20241022_613.pdf",
            date: "22-10-2024"
        }
}

export const newsIDList = [
    "TB_20241022_613",
    "TB_20241022_611",
    "TB_20241016_607",
    "TB_20241015_609",
    "TB_20241015_605",
    "TB_20241014_599",
    "TB_20241014_595",
    "TB_20241011_579",
    "TB_20241010_584",
    "TB_20241009_623",
    "TB_20241009_621",
    "TB_20241009_591",
    "TB_20241009_588",
    "TB_20241008_577",
    "TB_20241008_575",
    "TB_20241007_592",
    "TB_20241007_561",
    "TB_20241005_536",
    "TB_20241004_573",
    "TB_20241004_559",
    "TB_20241003_571",
    "TB_20241003_567",
    "TB_20241003_533",
    "TB_20241002_563",
    "TB_20241002_557",
    "TB_20241002_555",
    "TB_20241001_543",
    "TB_20241001_539",
    "TB_20240927_513",
    "TB_20240927_509_0001",
    "TB_20240925_541",
    "TB_20240923_549",
    "TBDG_122_0001",
    "TBDG_119_0001",
    "TBDG_117",
    "TBDG_10808_0001",
    "TBDG_101_0001",
    "TBDG_115_0001",
    "TBDG_97_0001",
    "TBDG_89_2024",
    "TBDG_93_0001",
    "TBDG_95_TAN_LONG_0001",
    "TBDG_84_0001",
    "TBDG_82_0001",
    "TBDG_31_STNMT_0001",
    "TBDB_52_22022024",
    "TBDG_53_22022024",
    "TB_148_2023",
    "TB_145_2023",
    "TB_157_2023",
    "TB_QC_VIETINBNAK_0001",
    "TB_QC_THA_DONG_HA_CHI_LY_0001",
    "TB_125_2023",
    "TB_QC_THA_THI_XA_0001",
    "TB_QC_TAU_THADS_TP_0001",
    "TB_QC_XE_THA_TP_DH_0001",
    "TB_88_2023",
    "TB_91_2023",
    "TB_94_2023",
    "TB_66_2023",
    "TB_64_2023",
    "TB_59_2023",
    "TB_26_2023",
    "TB_37_PL_0001"
];
// "TB_15_2023_0004", "tb_08_2023", "tb_09_2023", "TB_23_2023_0001"
export const currentBid = newsData.TB_20241022_613;

export const notFoundNews = {
    title: "Không tìm thấy thông báo",
    pdfFile: "",
    image: "",
};